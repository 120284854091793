import React from "react"
// import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import Layout, { Body } from "../Shared/Layout"
import transformImageUri from "../../utils/transformImageUri"
import SEO from "../Shared/Seo"

import Card from "../Shared/Card2"
import styles from "./ServicePage.module.scss"

const ServicePage = ({ service }) => {
  const { title, description, technologies } = service

  return (
    <Layout>
      <SEO
        title={`Services - ${title}`}
        description="Through our services framework of Consult, Design, Engineer, Operate and Optimize, our teams of technologists, strategists and designers deliver powerful digital experiences."
      />
      <div className={styles.wrap}>
        <Body className={styles.row}>
          <div className={styles.left}>
            <h1>{title}</h1>
            <div className={styles.content}>
              <ReactMarkdown
                source={description}
                transformImageUri={transformImageUri}
              />
            </div>
          </div>
        </Body>
        <Body className={styles.technologiesWrap}>
          <div className={styles.technologies}>
            {technologies.map(technology => (
              <Card
                title={technology.title}
                icon={technology.icon}
                to={`/technologies/${technology.slug}/`}
                key={technology.slug}
              >
                {technology.shortDescription}
              </Card>
            ))}
          </div>
        </Body>
      </div>
    </Layout>
  )
}

export default ServicePage
