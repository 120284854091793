import React from "react"
import { Link } from "gatsby"

import Icon from "../Icon"
import styles from "./Card.module.scss"

const Card = ({ title, children, icon, to = "/" }) => (
  <div className={styles.container}>
    <Link to={to} className={styles.content}>
      <div className={styles.icon}>
        <Icon icon={icon} size={45} />
      </div>
      <div className={styles.body}>
        <h4>{title}</h4>
        <p className={styles.subtitle}>{children}</p>
      </div>
    </Link>
  </div>
)

export default Card
