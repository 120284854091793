import React from "react"
import { graphql } from "gatsby"
import ServicePage from "../components/ServicePage"

const ServiceTemplate = ({ data }) => {
  const { strapiService } = data

  return <ServicePage service={strapiService} />
}

export const query = graphql`
  query ServiceTemplate($id: String!) {
    strapiService(slug: { eq: $id }) {
      title
      description
      technologies {
        title
        icon
        shortDescription
        slug
      }
    }
  }
`

export default ServiceTemplate
